<template>
  <div class="datacenter">
    <Header back="返回" title="数据中心" index="首页" beforeTitle="数据中心" />
    <div class="content">
      <div style="display:flex">
        <div class="dataleft">
          <h1>{{ title }}</h1>
          <h2>撰写人：{{ true_name }}</h2>
          <p style="display:block;width:100%;overflow:auto" v-html="content"></p>
        </div>
        <div class="dataright">
          <h1>附件</h1>
          <ul>
            <el-link
              style="width: 100%"
              v-for="item in fileList"
              :key="item.id"
              :href="userSite + item.url"
              target="_blank"
            >
              <li>{{ item.name }}</li>
            </el-link>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import site from "../../components/global.vue";
export default {
  data () {
    return {
      userSite: site.userSite,
      Url: "",
      fileList: [],
      title: "",
      content: "",
      id: "",
      true_name: "",
    };
  },
  computed: {
    meber_id () {
      return this.$route.query.id;
    },
  },
  created () {
  },
  mounted () {
    if (this.meber_id) {
      this.axios
        .get("/api/data/view", {
          params: {
            id: this.meber_id,
          },
        })
        .then((res) => {
          this.title = res.data.title;
          this.content = res.data.content;
          this.id = res.data.id;
          this.true_name = res.data.true_name;
          console.log(res.data.file);
          this.fileList = res.data.file;
        });
    }
  },
};
</script>

<style scoped>
.datacenter {
  height: 100%;
}

.dataleft {
  width: 65%;
  border-right: 1px solid #e1e2eb;
  padding-right: 2%;
  margin-right: 2%;
}

.dataleft h1 {
  font-size: 20px;
  font-weight: bold;
  line-height: 14px;
  color: #000000;
}

.dataleft h2 {
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  color: #a6abc7;
  margin-bottom: 20px;
}
.dataright {
  width: 30%;
}

.el-upload__tip {
  color: #a6abc7;
  margin-bottom: 10px;
}
::v-deep .el-link--inner {
  width: 100%;
  text-align: left;
  display: block;
  margin-bottom: 10px;
}
</style>
